<template>

  <div class="home-container">
    <div class="home-card">
      <el-card class="home-card-item" shadow="hover"  >
        <div slot="header" class="home-card-header">
          <i class="el-icon-key"></i>
          <span>密码个数</span>
        </div>
        <div class="home-card-content" >
          <p v-if="passwordCount !== null" @click="goToSitePassword" class="homea">{{ passwordCount }}</p>
          <p v-else>加载中...</p>
        </div>
      </el-card>
    </div>
    <div class="home-card">
      <el-card class="home-card-item" shadow="hover" >
        <div slot="header" class="home-card-header">
          <i class="el-icon-notebook-2"></i>
          <span>任务列表个数</span>
        </div>
        <div class="home-card-content" >
          <p v-if="taskCount !== null" @click="goToTask" class="homea">{{ taskCount }}</p>
          <p v-else>加载中...</p>
        </div>
      </el-card>
    </div>
    <el-dialog
      v-if="errorMessage"
      title="错误"
      :visible.sync="dialogVisible"
      width="30%">
      <span>{{ errorMessage }}</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '@/config';

export default {
  data() {
    return {
      passwordCount: null,
      taskCount: null,
      errorMessage: '',
      dialogVisible: false
    };
  },
  mounted() {
    this.fetchPasswordCount();
    this.fetchTaskCount();
  },
  methods: {
    async fetchPasswordCount() {
      try {
        const response = await axios.get('/count_site_user_password');
        const responseData = response.data;
        if (responseData.code === '0000') {
          this.passwordCount = responseData.data;
        } else {
          this.showErrorDialog(responseData.msg);
        }
      } catch (error) {
        console.error('Error fetching password count:', error);
        this.showErrorDialog('请求密码个数时出错');
      }
    },
    async fetchTaskCount() {
      try {
        const response = await axios.get('/task_count');
        const responseData = response.data;
        if (responseData.code === '0000') {
          this.taskCount = responseData.data;
        } else {
          this.showErrorDialog(responseData.msg);
        }
      } catch (error) {
        console.error('Error fetching task count:', error);
        this.showErrorDialog('请求任务个数时出错');
      }
    },
    showErrorDialog(message) {
      this.errorMessage = message;
      this.dialogVisible = true;
    },
    goToSitePassword() {
      this.$router.push('/sitepasswd');
    },
    goToTask() {
      this.$router.push('/task');
    }
  }
};
</script>

<style scoped>
.home-container {
  display: flex;
  justify-content: space-around;
}

.home-card {
  width: 45%;
}

.home-card-item {
  width: 100%;
}

.home-card-header {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.home-card-header i {
  margin-right: 10px;
}

.home-card-content {
  text-align: center;
  padding: 20px;
}
.homea {
  color: blue; /* 设置链接文字颜色为蓝色 */
  text-decoration: none; /* 移除下划线 */
}
.homea:hover {

  cursor: pointer; /* 设置鼠标悬停时的指针样式为手形 */
}

</style>
