<template>
  <div>
    <div class="container">
      <el-descriptions :title="count_list_name_title" direction="vertical" :column="4" border></el-descriptions>
      <div>
        <el-button type="primary" @click="handleAdd">增加一个任务</el-button>
        <el-button type="primary" @click="handleNoneExpire">未过期任务</el-button>
        <el-button type="info" @click="handleExpire">过期任务</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="formatted_task_end_time" label="任务到期日期(阳历)">
        <template slot-scope="scope">
          <span :style="{ color: rowColor(scope.row) }" v-if="!scope.row.editing">{{
              scope.row.formatted_task_end_time
            }}</span>
          <!--          <el-date-picker v-else v-model="scope.row.tempEndTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>-->
          <el-date-picker v-else-if="!scope.row.disableTaskEndTimeEditing" v-model="scope.row.tempEndTime" type="date"
                          placeholder="选择日期" value-format="yyyy-MM-dd"
                          @change="convertToLunar(scope.row)"></el-date-picker>
          <span v-else>{{ scope.row.tempEndTime }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="formatted_task_end_lunar_time" label="任务到期日期(农历)">
        <template slot-scope="scope">
          <span :style="{ color: rowColor(scope.row) }" v-if="!scope.row.editing">{{
              scope.row.task_end_lunar_time
            }}</span>
          <!--          <el-date-picker v-else v-model="scope.row.task_end_lunar_time" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>-->
          <el-date-picker v-else-if="!scope.row.disableTaskEndLunarTimeEditing" v-model="scope.row.tempLunarTime"
                          type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                          @change="convertToSolar(scope.row)"></el-date-picker>
          <span v-else>{{ scope.row.tempLunarTime }}</span>
        </template>
      </el-table-column>


      <el-table-column prop="task_comment" label="任务备注">
        <template slot-scope="scope">
          <span v-if="!scope.row.editing" :style="{ color: rowColor(scope.row) }">{{ scope.row.task_comment }}</span>
          <el-input v-else v-model="scope.row.tempComment" placeholder="请输入任务备注"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="schedule_flag" label="调度策略">
        <template slot-scope="scope">
          <span v-if="!scope.row.editing"
                :style="{ color: rowColor(scope.row) }">{{ getScheduleFlagText(scope.row.schedule_flag) }}</span>
          <el-select v-else v-model="scope.row.tempScheduleFlag" placeholder="请选择调度策略">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="is_lunar" label="是否农历">
        <template slot-scope="scope">
          <span v-if="!scope.row.editing"
                :style="{ color: rowColor(scope.row) }">{{ getLunarFlagText(scope.row.is_lunar) }}</span>
          <el-select v-else v-model="scope.row.tempIsLunar" placeholder="选择是否农历" @change="startEditing(scope.row)">
            <el-option
                v-for="item in options_is_lunar"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button v-if="!scope.row.editing" type="primary" size="mini" @click="startEditing(scope.row)">编辑
          </el-button>
          <el-button v-else type="success" size="mini" @click="confirmEditing(scope.row)">确认</el-button>
          <el-button v-if="scope.row.editing" type="danger" size="mini" @click="cancelEditing(scope.row)">取消</el-button>
          <el-popconfirm v-if="!scope.row.editing" title="确定删除这一行吗？" confirmButtonText="确定" cancelButtonText="取消"
                         @confirm="handleDelete(scope.row)">
            <el-button type="danger" slot="reference" size="mini">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        title="添加任务"
        :visible.sync="addTaskDialogVisible">

      <el-form :model="formData" label-width="200px" size="medium" label-position="left">
        <div class="task_lunar">
          <el-form-item label="任务到期日(公历)">
            <el-date-picker
                v-model="formData.task_end_time"
                :disabled="lunarSelected"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                style="width: 100%;"
                @change="hadleDiaglogSolarDate(formData.task_end_time)">
            </el-date-picker>
          </el-form-item>
        </div>

        <div class="task_lunar">
          <el-form-item label="任务到期日(农历)">
            <el-date-picker
                v-model="formData.task_end_lunar_time"
                :disabled="!lunarSelected"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                style="width: 100%;"
                @change="hadleDiaglogLunarDate(formData.task_end_lunar_time)">
            </el-date-picker>
          </el-form-item>
          <el-checkbox label="是否农历" name="type" v-model="formData.is_lunar"></el-checkbox>
        </div>
        <el-form-item label="任务备注">
          <el-input v-model="formData.task_comment" type="textarea"></el-input>
        </el-form-item>

        <el-form-item label="调度策略">
          <el-select v-model="formData.schedule_flag" placeholder="请选择调度策略" style="width: 100%;">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                style="width: 100%;">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTaskDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        v-if="errorMessage"
        title="编辑结果"
        :visible.sync="dialogVisible">
      <span>{{ errorMessage }}</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '@/config';
import LunarCalendar from 'lunar-calendar';

export default {
  data() {
    return {
      count_list_name_title: '倒计时任务列表',
      tmpRowData: '',
      tableData: [],
      expiredTableData: [],
      noneExpiredTableData: [],
      tableDataTmp: [],
      handleExpireFlag: '',
      formData: {
        task_end_time: '',
        task_comment: '',
        schedule_flag: '',
        is_lunar: '',
        task_end_lunar_time: ''
      },
      options: [
        {
          value: 'year',
          label: '每年'
        },
        {
          value: 'month',
          label: '每月'
        }, {
          value: '',
          label: '一次性'
        }],
      options_is_lunar: [
        {
          value: 'Y',
          label: '是'
        },
        {
          value: 'N',
          label: '否'
        },
      ],
      errorMessage: '',
      dialogVisible: false,
      addTaskDialogVisible: false,
      lunarSelected: false,
      lunarBirthday: '',
      pickerOptions: {
        cellClassName: date => {
          const now = new Date(date)
          const y = now.getFullYear()
          const m = now.getMonth() + 1
          const day = now.getDate()
          const {lunarMonthName, lunarDayName} = LunarCalendar.solarToLunar(y, m, day)
          return `lunar-cell-month-${lunarMonthName} lunar-cell-day-${lunarDayName} `
        }
      }

    };
  },
  mounted() {
    this.fetchData('y');
  },
  watch: {
    // Watch for changes in the '是否农历' checkbox
    'formData.is_lunar': function (newValue) {
      // console.log(newValue)
      // console.log(this.formData.is_lunar)
      // console.log(this.lunarSelected)
      // If '是否农历' is selected, disable '任务到期日(公历)' and enable '任务到期日(农历)'
      // If '是否农历' is deselected, disable '任务到期日(农历)' and enable '任务到期日(公历)'
      if (newValue) {
        this.lunarSelected = true;
        // this.formData.task_end_time = ''; // Reset '任务到期日(公历)' when '是否农历' is selected
      } else {
        this.lunarSelected = false
        // this.formData.task_end_lunar_time = ''; // Reset '任务到期日(农历)' when '是否农历' is deselected
      }
      // console.log(this.lunarSelected)
    }
  },

  methods: {


    hadleDiaglogLunarDate(lunarDate) {
      // console.log(lunarDate)
      this.formData.task_end_time = this.lunarToSolar(lunarDate)
    },
    hadleDiaglogSolarDate(solarDate) {
      this.formData.task_end_lunar_time = this.solarToLunar(solarDate)
    },

    convertToSolar(row) {
      // console.log(row)
      // 获取农历日期
      const lunarDate = row.tempLunarTime;
      // 调用转换函数获取农历日期
      // console.log(lunarDate)
      const solarDate = this.lunarToSolar(lunarDate);
      // 将转换后的农历日期设置到任务到期日期(农历)列
      // console.log(solarDate)
      row.tempEndTime = solarDate;
    },
    lunarToSolar(lunarDate) {
      // 例如，传递的农历日期格式为 2024-01-25
      const [lunarYear, lunarMonth, lunarDay] = lunarDate.split('-').map(Number);
      // 调用 lunar-calendar 库的方法进行转换
      const solar = LunarCalendar.lunarToSolar(lunarYear, lunarMonth, lunarDay);
      // console.log(solar)

      // 获取转换后的阳历日期
      const solarDate = `${solar.year}-${solar.month}-${solar.day}`;
      // 返回转换后的阳历日期
      return solarDate;
    },

    convertToLunar(row) {
      // 获取阳历日期
      const solarDate = row.tempEndTime;
      // 调用转换函数获取农历日期
      const lunarDate = this.solarToLunar(solarDate);
      // 将转换后的农历日期设置到任务到期日期(农历)列
      row.tempLunarTime = lunarDate;
    },
    solarToLunar(solarDate) {
      // 将阳历日期转换为年、月、日
      const [year, month, day] = solarDate.split('-').map(Number);
      // 调用 lunar-calendar 库的方法进行转换
      const lunar = LunarCalendar.solarToLunar(year, month, day);
      // 获取转换后的农历日期
      const lunarYear = lunar.lunarYear;
      let lunarMonth = lunar.lunarMonth;
      let lunarDay = lunar.lunarDay;
      // 修正超出范围的农历月份和日期
      if (lunarMonth < 10) {
        lunarMonth = '0' + lunarMonth;
      }
      if (lunarDay < 10) {
        lunarDay = '0' + lunarDay;
      }
      // 拼接农历日期
      const lunarDate = `${lunarYear}-${lunarMonth}-${lunarDay}`;
      // 返回转换后的农历日期
      return lunarDate;
    },

    async fetchData(is_first) {
      try {
        const response = await axios.get('/task_list');
        const responseData = response.data;
        if (responseData.code === '0000') {
          const tasks = responseData.data.map(task => ({
            ...task,
            formatted_task_end_time: task.task_end_time,
            editing: false,
            tempEndTime: task.task_end_time,
            tempComment: task.task_comment,
            tempScheduleFlag: task.schedule_flag,
            formatted_task_end_lunar_time: task.task_end_lunar_time,
            tempLunarTime: task.task_end_lunar_time,
            tempIsLunar: task.is_lunar,
          }));

          const Date1 = new Date()
          Date1.setHours(7)
          Date1.setMinutes(59)
          Date1.setSeconds(59)

          const expiredTasks = tasks.filter(task => new Date(task.task_end_time) < Date1);
          const nonExpiredTasks = tasks.filter(task => new Date(task.task_end_time) >= Date1);

          this.noneExpiredTableData = [...nonExpiredTasks]
          this.expiredTableData = [...expiredTasks]
          if (is_first === 'y') {
            this.tableData = this.noneExpiredTableData
            this.handleExpireFlag = 'noneExpire'
          } else {
            if (this.handleExpireFlag === 'expire') {
              this.tableData = this.expiredTableData
            } else if (this.handleExpireFlag === 'noneExpire') {
              this.tableData = this.noneExpiredTableData
            }
          }
        } else {
          this.showErrorDialog(responseData.msg);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        this.showErrorDialog('请求数据时出错');
      }
    },

    // tableRowClassName({row}) {
    //   const taskEndTime = new Date(row.task_end_time);
    //   const currentDate = new Date();
    //   currentDate.setHours(7)
    //   currentDate.setHours(59)
    //   currentDate.setMinutes(59)
    //   const timeDiff = taskEndTime.getTime() - currentDate.getTime();
    //   const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    //
    //   if (timeDiff < 0) {
    //     return 'expired-task';
    //   } else if (daysDiff <= 7) {
    //     return 'near-deadline-task';
    //   } else if (daysDiff <= 15) {
    //     return 'soon-deadline-task';
    //   } else if (daysDiff <= 30) {
    //     return 'upcoming-deadline-task';
    //   } else {
    //     return 'normal-task';
    //   }
    // },
    rowColor(row) {
      const taskEndTime = new Date(row.task_end_time);
      const currentDate = new Date();
      currentDate.setHours(8)
      currentDate.setHours(1)
      currentDate.setMinutes(0)

      const timeDiff = taskEndTime.getTime() - currentDate.getTime();
      const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      if (timeDiff < 0) {
        return 'gray'; // 灰色
      } else if (daysDiff <= 7) {
        return '#FF0000'; // 红色
      } else if (daysDiff <= 15) {
        return '#FFA500'; // 橙色
      } else if (daysDiff <= 30) {
        return 'green'; // 黄色
      } else {
        return 'black'; // 绿色
      }
    },
    handleAdd() {
      this.addTaskDialogVisible = true;
    },
    handleExpire() {
      this.tableData = this.expiredTableData;
      this.handleExpireFlag = 'expire'
      this.count_list_name_title = '倒计时任务列表(已过期)'
    },
    handleNoneExpire() {
      this.tableData = this.noneExpiredTableData;
      this.handleExpireFlag = 'noneExpire'
      this.count_list_name_title = '倒计时任务列表'

    },
    async handleConfirm() {
      try {

        const response = await axios.post('/add_task', this.formData);
        const responseData = response.data;
        if (responseData.code === '0000') {
          this.addTaskDialogVisible = false;
          this.fetchData();
        } else {
          this.showErrorDialog(responseData.msg);
        }
      } catch (error) {
        console.error('Error adding task:', error);
        this.showErrorDialog('添加任务时出错');
      }
    },

    // categoryChange(row) {
    //   console.log(row, 2222222222)
    // },

    startEditing(row) {
      // 获取编辑行的数据
      const rowData = this.tableData.find(item => item.id === row.id);
      // 检查是否农历的值
      if (rowData.tempIsLunar === 'Y') {
        // 如果是否农历为是，只允许编辑任务到期日期(农历)
        row.editing = true;
        row.disableTaskEndLunarTimeEditing = false;
        row.disableTaskEndTimeEditing = true;
      } else {
        // 如果是否农历为否，允许编辑任务到期日期
        row.editing = true;
        // 同时将任务到期日期(农历)设为不可编辑状态
        row.disableTaskEndLunarTimeEditing = true;
        row.disableTaskEndTimeEditing = false;
      }

    },
    async confirmEditing(row) {
      try {
        const response = await axios.put(`/update_task/${row.id}`, {
          task_end_time: row.tempEndTime,
          task_comment: row.tempComment,
          schedule_flag: row.tempScheduleFlag,
          is_lunar: row.tempIsLunar,
          task_end_lunar_time: row.tempLunarTime
        });
        const responseData = response.data;
        if (responseData.code === '0000') {
          row.editing = false;
          this.fetchData();
          this.showErrorDialog('编辑成功');
        } else {
          this.showErrorDialog('后端接口报错' + responseData.msg);
        }
      } catch (error) {
        console.error('Error editing task:', error);
        this.showErrorDialog('编辑任务时出错' + error);
      }
    },
    cancelEditing(row) {
      row.editing = false;
      row.disableTaskEndLunarTimeEditing = false;
      row.disableTaskEndTimeEditing = false;
      // 将任务到期日期(农历)的临时值恢复为原始值
      row.tempLunarTime = row.task_end_lunar_time;

      // 将任务到期日期的临时值恢复为原始值
      row.tempEndTime = row.formatted_task_end_time;

      // 将任务备注的临时值恢复为原始值
      row.tempComment = row.task_comment;

      row.tempIsLunar = row.is_lunar;

      // 将调度策略的临时值恢复为原始值
      row.tempScheduleFlag = row.schedule_flag;


    },
    async handleDelete(row) {
      try {
        const response = await axios.delete(`/delete_task/${row.id}`);
        const responseData = response.data;
        if (responseData.code === '0000') {
          this.fetchData();
        } else {
          this.showErrorDialog(responseData.msg);
        }
      } catch (error) {
        console.error('Error deleting task:', error);
        this.showErrorDialog('删除任务时出错');
      }
    },
    showErrorDialog(message) {
      this.errorMessage = message;
      this.dialogVisible = true;
    },
    getScheduleFlagText(scheduleFlag) {
      const flagTextMap = {
        'year': '每年',
        'month': '每月',
        '': '一次性'
      };
      return flagTextMap[scheduleFlag] || '未知'; // 若未匹配到则返回'未知'
    },
    getLunarFlagText(is_lunar) {
      const flagTextMap = {
        'Y': '是',
        'N': '否'
      };
      return flagTextMap[is_lunar] || '未知'; // 若未匹配到则返回'未知'
    },
    showDeleteConfirm(row) {
      this.$confirm('确认删除此任务？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.handleDelete(row);
      }).catch(() => {
        // 取消删除
      });
    }
  }
};
</script>

<style>
.container {
  display: flex;
  justify-content: space-between;
}

.task_lunar {
  display: flex;
  justify-content: space-between;
}

.task_lunar1 {
  display: flex;
  justify-content: space-between;
}

.el-table .expired-task {
  background-color: #d3d3d3; /* 灰色 */
  z-index: 1; /* 设置灰色行的 z-index */
}

.el-table .near-deadline-task {
  background: #ffcccc; /* 浅红色 */
}

.el-table .soon-deadline-task {
  background: #ffffcc; /* 黄色 */
}

.el-table .upcoming-deadline-task {
  background: palegreen; /* 绿色 */
}

.el-table .normal-task {
  /* 默认样式 */
  background: #7FFFD4;
}

label {
  display: flex;
  width: 300px;
  justify-content: space-between;
  align-items: center;
}

.el-date-picker td div:after {
  content: attr(data-content);
  display: block;
  height: 20px;
  font-size: 12px;
  margin-top: 20px;
}

.el-date-picker td div span {
  flex: 1;
}

.el-date-table td.today div::after {
  color: #409EFF;
  font-weight: 700;
}

.el-date-picker td div {
  display: flex;
  height: 50px;
  justify-content: space-between;
  flex-direction: column;
}

.el-date-table td.current:not(.disabled) div {
  color: #fff;
  background-color: #409EFF;
}

.el-date-table td.current:not(.disabled) span {
  color: #FFF;
  background-color: transparent;
}


</style>