<template>
  <div id="app" ref="chatContainer">

    <div class="sidebar">
      <ul>
        <li @click="newChat" class="newConversation">新建对话</li>
 <br/>
      </ul>
      <ul>

        <li v-for="(item, index) in chats" :key="index" @click="fetchChatMessages(item.id) " class="chatList"
            @dblclick="editChatName(index)">
<!--          <div class="chat-info">-->
            <!--          <span>{{ chat.chat_name }}</span>-->
            <span v-if="index !== editingIndex">{{ item.chat_name }}</span>
            <el-input ref="inputRef" v-else v-model="editedItem" @blur="updateChatName(item)" autofocus></el-input>

                        <el-dropdown trigger="click" >
            <!--              用click.stop 点击...不在调用fetchChatMessages-->
                          <span class="el-dropdown-link" @click.stop="">...</span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="deleteChat(item.id)">删除</el-dropdown-item>
                            <el-dropdown-item @click.native="editChatName(index)">重命名</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
<!--          </div>-->
        </li>


      </ul>
    </div>


    <div class="chatContiner">


      <div class="message-card" ref="messagesContainer">
      <div class="messages">
        <div v-for="(msg, index) in messages" :key="index">
          <!-- 使用 vue-markdown 组件来解析 Markdown 格式的消息内容 -->
          <p class="message"><span :class="msg.role">{{ msg.role === 'user' ? 'User:' : 'Assistant:' }}</span> <span><vue-markdown :source="msg.content" /></span></p>
        </div>
      </div>
    </div>
<!--      <div class="message-card" ref="messagesContainer">-->
<!--        <div class="messages">-->
<!--          <div v-for="(msg, index) in messages" :key="index">-->
<!--            <p class="message"><span :class="msg.role">{{ msg.role === 'user' ? 'User:' : 'Assistant:' }}</span> <span><pre>{{-->
<!--                msg.content-->
<!--              }}</pre></span></p>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
      <div class="input-container">

        <el-input v-model="userInput"
                  placeholder="发送消息给ChatGpt..."
                  class="fixed-height-input"
                  type="textarea"
                  :rows="2"
                  :resize="resize"
                  @keydown.native="Keydown"
        ></el-input>
        <el-button type="success" native-type="submit" :disabled="userInput.trim() === ''" :loading="sendingMessage"
                   @click="sendMessage">Send
        </el-button>
        <!--  <el-form class="input-form" ref="inputForm" @submit.native.prevent="sendMessage">-->
        <!--    <el-form-item style="flex: 1;">-->
        <!--      <el-input v-model="userInput"-->
        <!--        placeholder="发送消息给ChatGpt..."-->
        <!--        class="fixed-height-input"-->
        <!--        type="textarea"-->
        <!--                :rows="2"-->
        <!--        :resize="resize"-->
        <!--        @keydown.enter.shift.prevent="insertNewLine"-->
        <!--      ></el-input>-->
        <!--    </el-form-item>-->


        <!--  </el-form>-->
        <!--  <el-button  type="primary" native-type="submit" :disabled="userInput.trim() === ''" :loading="sendingMessage" style="display: inline-block;">Send</el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/config';
import VueMarkdown from 'vue-markdown';

export default {
  name: 'App',
components: {
    VueMarkdown // 注册 VueMarkdown 组件
  },
  data() {
    return {
      userInput: '',
      messages: [],
      eventSource: null,
      maxMessages: 100, // 最大消息数量
      sendingMessage: false,
      resize: 'none',
      inputText: '',
      chats: [],
      msg1: 'init',
      global_chat_id: -1,
      // chat_name_tmp: '',
      // chat_editing: '',

      editingIndex: null,
      editedItem: '',

      // waitingForResponse: false // 新增变量，表示是否正在等待接口响应

    };
  },
  methods: {
    updateChatName(chat) {

      if (this.editedItem === chat.chat_name) {
        this.editingIndex = null;
        this.fetchChats();
        return
      }
      // 调用 updateChat 方法更新对话名称
      // this.updateChat(chat.id, { chat_name: chat.chat_name_edit });
      // this.updateChat(chat.id, {chat_name: this.chat_name_tmp});
      this.updateChat(chat.id, {chat_name: this.editedItem});

      // 结束编辑状态

      this.editingIndex = null;
      this.chat_editing = false
      chat.isEditing = false;
    },
    editChatName(index) {
      // console.log(111)
      this.editingIndex = index;
      // console.log(this.editingIndex)
      // console.log(this.chats,222)
      this.editedItem = this.chats[index].chat_name;
this.$nextTick(() => {
  // console.log(this.$refs.inputRef[0].$el,111111111)
        this.$refs.inputRef[0].$el.focus();
      });
  //     setTimeout(() => {
  //   const inputElement = this.$refs.input.$el.querySelector('input');
  //   inputElement && inputElement.focus(); // Focus the input field when editing starts
  // }, 0);

      // this.$nextTick(() => {
      //   this.$refs.input.focus(); // Focus the input field when editing starts
      // });


      //     console.log(chat)
      // // 将当前对话名称赋值给 chat_name_edit 变量
      //     this.chat_name_tmp = chat.chat_name
      //     this.chat_editing = true
      // // 将 chat 对象保存在 data 中，以便在编辑完成后使用
      // this.$set(chat, 'isEditing', true);

    },
    newChat() {
      this.messages = []
      this.userInput = ''
      this.global_chat_id = -1
    },

    updateChat(chatId, data) {
      axios.put(`/update_chat/${chatId}`, data)
          .then(response => {
            const responseData = response.data;
            if (responseData.code === '0000') {
              // console.log('Chat updated successfully');
              this.editingIndex = null;
              // this.chat_name_tmp = ''
              this.fetchChats();
            } else {
              console.error('Failed to update chat:', responseData.msg);
            }
          })
          .catch(error => {
            console.error('Error updating chat:', error);
          });
    },
    fetchChatMessages(chatId) {
      if (chatId === this.global_chat_id) {
        // console.log('xiangdeng ,tuichu')
        return
      }
      axios.get(`/get_one_chat/${chatId}`)
          .then(response => {
            const responseData = response.data;
            if (responseData.code === '0000') {
              // 清空之前的消息
              this.messages = [];
              // 添加新的消息

              const chatData = JSON.parse(responseData.data[0].chat_content);
// 遍历数组，并将用户和助手消息添加到 this.messages 中

              chatData.forEach(item => {
                // console.log('foreach', item)
                this.messages.push({role: item.role, content: item.content});
                // this.messages.push({ role: 'user', content: item.user });
                // this.messages.push({ role: 'assistant', content: item.Assistant });
              });
              this.global_chat_id = responseData.data[0].id
            } else {
              console.error('Failed to fetch chat messages:', responseData.msg);
            }
          })
          .catch(error => {
            console.error('Error fetching chat messages:', error);
          });
    },

    deleteChat(chatId) {
      // console.log('delete', chatId, 11111)
      axios.delete(`/delete_chat/${chatId}`)
          .then(response => {
            const responseData = response.data;
            if (responseData.code === '0000') {
              // 如果删除的是当前展示的对话，则调用 newChat 方法清空消息和输入框
              if (chatId === this.global_chat_id) {
                this.newChat();
              }
              // Remove the deleted chat from the local list
              this.chats = this.chats.filter(chat => chat.id !== chatId);

              // console.log('Chat deleted successfully');
            } else {
              console.error('Failed to delete chat:', responseData.msg);
            }
          })
          .catch(error => {
            console.error('Error deleting chat:', error);
          });
    },

    Keydown(e) {

      if (!e.shiftKey && e.keyCode == 13) {

        e.cancelBubble = true; //ie阻止冒泡行为
        e.stopPropagation();//Firefox阻止冒泡行为
        e.preventDefault(); //取消事件的默认动作*换行
        //以下处理发送消息代码
        // onSendMsg();
        this.sendMessage()
      }
    },
    addChat(data) {
      axios.post('/add_chat', data)
          .then(response => {
            const responseData = response.data;
            if (responseData.code === '0000') {
              // console.log('Chat added successfully');
              this.global_chat_id = responseData.last_insert_id
              this.fetchChats();
            } else {
              console.error('Failed to add chat:', responseData.msg);
            }
          })
          .catch(error => {
            console.error('Error adding chat:', error);
          });
    },

// 定义处理消息的函数
 processMessage(userMessage, aiResponse, isThinking) {
  const assistantMessageIndex = this.messages.findIndex(msg => msg.content === '机器人正在思考...');
  const responseContent = isThinking ? aiResponse : '机器人脑子宕机了，请重新发送消息';

  if (assistantMessageIndex !== -1) {
    this.messages.splice(assistantMessageIndex, 1, { role: 'assistant', content: responseContent });

    if (this.msg1.length === 0) {
      this.addChat({ chat_name: userMessage.substring(0, 10), chat_content: this.messages });
      this.msg1 = 'init';
    } else {
      this.updateChat(this.global_chat_id, { chat_content: this.messages });
    }
  } else {
    this.messages.push({ role: 'assistant', content: responseContent });
  }

  this.$nextTick(() => {
    this.scrollMessagesToBottom(); // 滚动到底部
  });
},

    sendMessage() {
      // console.log(this.messages[0], this.messages.length, 1112)
      // console.log(this.messages.length, 11112)
      const userMessage = this.userInput.trim();

      if (this.sendingMessage ) {
        // 如果正在等待接口响应或用户消息为空，则不执行发送消息的操作
          this.$notify({
          title: '通知通知..🔊🔊🔔🔔',
          message: '༼ つ ◕_◕ ༽つ 别动, 机器人正在思考...',
          type: 'success'
        });

        return;
      }

      if (this.messages.length === 0) {

        this.msg1 = ''
      }

      if (userMessage) {
        this.sendingMessage = true;
        this.messages.push({role: 'user', content: userMessage});

        this.userInput = '';
        this.messages.push({role: 'assistant', content: '机器人正在思考...'});

// this.$nextTick(() => {
//               this.scrollMessagesToBottom(); // 滚动到底部
//   this.sendingMessage = false;
//             });

        // 使用 Axios 发送用户输入消息到后端
        axios.post('/chat', {user_input: userMessage})
            .then(response => {
                  // const aiResponse = response.data.message;
                  // const assistantMessageIndex = this.messages.findIndex(msg => msg.content === '机器人正在思考...');
                  // if (assistantMessageIndex !== -1) {
                  //   this.messages.splice(assistantMessageIndex, 1, {role: 'assistant', content: aiResponse});
                  //   // 调用 add_chat 接口
                  //   // console.log(this.msg1.length, 113)
                  //   if (this.msg1.length === 0) {
                  //     // console.log(this.msg1, 333333)
                  //     this.addChat({chat_name: userMessage.substring(0, 10), chat_content: this.messages});
                  //     this.msg1 = 'init';
                  //
                  //   } else {
                  //     // 否则调用 updateChat 方法更新聊天记录
                  //     this.updateChat(this.global_chat_id, {chat_content: this.messages});
                  //   }
                  //   // console.log('destory sikao', this.messages)
                  // } else {
                  //   this.messages.push({role: 'assistant', content: aiResponse});
                  //   // console.log('append response', this.messages)
                  //
                  // }
                  // // console.log('finally', this.messages)
                  // this.$nextTick(() => {
                  //   this.scrollMessagesToBottom(); // 滚动到底部
                  // });
              const aiResponse = response.data.message;
            this.processMessage.call(this, userMessage, aiResponse, true); // 处理正常的AI响应
                }
            )
            .catch(error => {
              console.error('Error:', error);
               this.processMessage.call(this, userMessage, null, false); // 处理AI宕机时的响应
            })
            .finally(() => {
              // this.waitingForResponse = false; // 接口响应完成，设置为 false
              this.sendingMessage = false;
            });
      }


    },
    scrollMessagesToBottom() {
      const messagesContainer = this.$refs.messagesContainer;
      const isScrolledToBottom = messagesContainer.scrollHeight - messagesContainer.clientHeight <= messagesContainer.scrollTop + 1;
      if (!isScrolledToBottom) {
        messagesContainer.scrollTop = messagesContainer.scrollHeight - messagesContainer.clientHeight;
      }
    },

    fetchChats() {
      axios.get('/list_chat')
          .then(response => {
            const responseData = response.data;

            if (responseData.code === '0000') {
              this.chats = responseData.data;

              // console.log('page fetchchats, this.messages length', this.messages.length)
            } else {
              console.error('Failed to fetch chats:', responseData.msg);
            }
          })
          .catch(error => {
            console.error('Error fetching chats:', error);
          });
    },

  },

  mounted() {
    // 使用 Server-Sent Events (SSE) 获取后端推送的消息
    // this.eventSource = new EventSource('http://127.0.0.1:5000/api/sse');
    // // 使用 Server-Sent Events (SSE) 获取后端推送的消息
    // this.eventSource.addEventListener('message', event => {
    //   const aiResponse = event.data;
    //   this.messages.push({ role: 'assistant', content: aiResponse });
    //
    //   // 检查消息数量是否超过最大值，如果超过则移除最早的消息
    //   if (this.messages.length > this.maxMessages) {
    //     this.messages.shift();
    //   }
    //
    //   this.$nextTick(() => {
    //     this.scrollMessagesToBottom(); // 滚动到底部
    //   });
    // });


    // console.log('page mount, this.messages length', this.messages.length)
    this.fetchChats();
    // console.log('page fetchChats, this.messages length', this.messages.length)
    // 页面初始化时滚动到底部
    this.$nextTick(() => {
      this.scrollMessagesToBottom();
      // console.log('page scrollMessagesToBottom, this.messages length', this.messages.length)
    });
    // console.log('page end mount, this.messages length', this.messages.length)
  },

};

</script>

<style scoped>
#app {
  display: flex;
  flex-direction: row;
  height: 90vh; /* 设置高度为视口高度，使其填满整个屏幕 */
}

.message-card {
  flex: 1; /* 让 message-card 占据剩余的空间 */
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.messages {
  width: 100%;
}

.messages {
  text-align: left;
  flex: 1;
  width: 100%;
}

.user {
  color: red; /* 设置用户消息的颜色为红色 */
}

.assistant {
  color: green; /* 设置助手消息的颜色为绿色 */
}

.input-container {
  display: flex;
  flex-shrink: 0; /* 不允许 input-container 收缩 */
  align-items: center; /* 垂直居中 */
  padding: 10px;
  border-top: 1px solid #ccc; /* 添加上边框分割线 */
}

.input-form {
  flex: 1; /* 让表单占据剩余的空间 */
  align-items: center; /* 垂直居中 */
}


.el-input {
  margin: 10px 0; /* 上下内边距为10像素 */

}

.el-button {
  margin: 1px 0; /* 上下内边距为10像素 */
  height: 55px;
}


.container {
  display: flex;
  flex-direction: row;
}

/*chat continer css*/
.chatContiner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}


/*sidebar css*/
.sidebar {
  flex: 0 0 auto; /* Don't grow or shrink */
  width: 10%;
  height: 100%;
  padding: 10px;
  /*background-color: #f0f0f0;*/
  overflow-y: auto;
}

.main-content {
  flex: 1 1 auto; /* Grow, shrink, and initial size */
  padding: 10px;
}

.sidebar h2 {
  margin-top: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  cursor: pointer;
  /*margin-bottom: 5px;*/
}

/*.sidebar ul li:hover {*/
/*  !*text-decoration: underline;*!*/
/*}*/

.sidebar h2, .sidebar ul li:first-child {
  position: sticky;
  top: 0;
  /*background-color: white; !* 可以根据需要设置背景色 *!*/
  z-index: 1; /* 确保固定元素位于其他内容之上 */
}


/*side bar li tag*/

.chat-info {
  display: flex;
  align-items: center; /* 垂直居中 */
  /*justify-content: space-between;*/
  /*align-items: center; !* 让子项在交叉轴上居中对齐 *!*/
}

.chat-info span {
  flex-grow: 1;
  /*text-align: left; !* 左对齐 *!*/
}

.button-container {
  flex-shrink: 0; /* 不允许按钮缩小 */
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}


/*对话框的虚线框*/
.newConversation {
  border: 1px dashed #ccc; /* Add a 1px dashed border with color #ccc */
  padding: 10px; /* Add padding to space the text from the border */
  border-radius: 5px; /* Add border radius for rounded corners */
  cursor: pointer; /* Change cursor to pointer on hover */
}
/*对话框的虚线框*/
.chatList {
  border: 1px dashed palegreen; /* Add a 1px dashed border with color #ccc */
  padding: 1px; /* Add padding to space the text from the border */
  border-radius: 5px; /* Add border radius for rounded corners */
  cursor: pointer; /* Change cursor to pointer on hover */
  height: 30px;
  display: flex;
  align-items: center; /* 垂直居中 */
  margin-bottom: 10px;

}
.chatList span {
  flex-grow: 1;
}



</style>
