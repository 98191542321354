<template>

  <div>
    <div class="container">
      <el-descriptions title="网站密码" direction="vertical" :column="4" border></el-descriptions>
      <el-button class="add-button" type="primary" @click="handleAdd">增加一个密码</el-button>
    </div>

    <el-table
        :data="tableData"
        stripe
        style="width: 100%"
    >
      <el-table-column
          prop="site"
          label="网站"
          width="180">
      </el-table-column>
      <el-table-column
          prop="site_user"
          label="网站用户"
          width="180">
      </el-table-column>
      <el-table-column
          prop="site_password"
          label="密码">
      </el-table-column>
      <el-table-column
          prop="password_digits"
          label="备注">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-popconfirm title="确定删除这一行吗？" confirmButtonText="确定" cancelButtonText="取消"
                         @confirm="handleDelete(scope.row)">
            <el-button type="danger" slot="reference" size="mini">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        title="添加网站密码"
        :visible.sync="dialogVisible">
      <el-form :model="formData" label-width="80px">
        <el-form-item label="网站">
          <el-input v-model="formData.site"></el-input>
        </el-form-item>
        <el-form-item label="网站用户">
          <el-input v-model="formData.site_user"></el-input>
        </el-form-item>
        <el-form-item label="密码策略">
          <el-input v-model="formData.password_digits"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        v-if="errorMessage"
        title="错误"
        :visible.sync="dialogVisible">
      <span>{{ errorMessage }}</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '@/config';

export default {
  data() {
    return {
      tableData: [],
      clickedRowIndex: -1,
      errorMessage: '',
      dialogVisible: false,
      formData: {
        site: '',
        site_user: '',
        password_digits: ''
      }
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {

    async handleDelete(row) {
      try {

        const response = await axios.delete(`/delete_site_user_password/${row.id}`);
        const responseData = response.data;
        if (responseData.code === '0000') {
          this.fetchData();
        } else {
          this.showErrorDialog(responseData.msg);
        }
      } catch (error) {
        console.error('Error deleting task:', error);
        this.showErrorDialog('删除任务时出错');
      }
    },

    async fetchData() {

      try {
        const response = await axios.get('/list_site_user_password');
        const json = response.data;
        if (json.code === '0000') {
          this.tableData = json.data;
        } else {
          this.showErrorDialog(json.msg);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        this.showErrorDialog('请求数据时出错');
      }
    },
    handleAdd() {
      this.dialogVisible = true;
    },
    async handleConfirm() {
      try {
        const response = await axios.post('/add_user_password', this.formData);
        const json = response.data;
        if (json.code === '0000') {
          this.dialogVisible = false;
          this.fetchData();
        } else {
          this.showErrorDialog(json.msg);
        }
      } catch (error) {
        console.error('Error adding data:', error);
        this.showErrorDialog('添加数据时出错');
      }
    },
    showErrorDialog(message) {
      this.errorMessage = message;
      this.dialogVisible = true;
    }
  }
};
</script>
<style>
.container {
  display: flex;
  justify-content: space-between;
}
</style>