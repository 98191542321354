<template>
  <div>
    <h2>修改密码</h2>
    <el-form ref="passwordForm" :model="passwordForm" label-width="120px">
      <el-form-item label="原密码" prop="oldPassword" :rules="[
        { required: true, message: '请输入原密码', trigger: 'blur' }]" >
        <el-input type="password" v-model="passwordForm.oldPassword" autocomplete="off" @blur="validateField('oldPassword')" show-password></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword" :rules="[
        { required: true, message: '请输入新密码', trigger: 'blur' },
        { validator: validateNoWhitespace, trigger: 'blur' }
      ]">
        <el-input type="password" v-model="passwordForm.newPassword" autocomplete="new-password" @blur="validateField('newPassword')" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="confirmPassword" :rules="[
        { required: true, message: '请再次输入新密码', trigger: 'blur' },
        { validator: validateConfirmPassword, trigger: 'blur' },
        { validator: validateNoWhitespace, trigger: 'blur' }
      ]">
        <el-input type="password" v-model="passwordForm.confirmPassword" autocomplete="new-password" @blur="validateField('confirmPassword')" show-password></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="changePassword">确认修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import JSEncrypt from 'jsencrypt';
import axios from '@/config';
import { Message } from 'element-ui';

export default {
  data() {
    return {
      passwordForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      }
    };
  },
  methods: {
    async changePassword() {
      try {
        // Validate form
        await this.$refs.passwordForm.validate();

        if (this.passwordForm.newPassword !== this.passwordForm.confirmPassword) {
          Message.error('两次输入的新密码不一致');
          return;
        }

        // Get public key
        const publicKey = localStorage.getItem('publicKey');
        // Encrypt new password
        const encryptedNewPassword = this.encryptPassword(publicKey, this.passwordForm.newPassword);
        const encryptedOldPassword = this.encryptPassword(publicKey, this.passwordForm.oldPassword);
        // Call backend API to change password
        const response = await axios.post(`/change_password`, {
          oldPassword: encryptedOldPassword,
          newPassword: encryptedNewPassword
        });
        const data = response.data;
        if (data.code === '0000') {
          Message.success('密码修改成功');
          // Clear form inputs
          this.$refs.passwordForm.resetFields();
        } else {
          console.error('Password change failed:', data.msg);
          Message.error(data.msg);
        }
      } catch (error) {
        console.error('Error changing password:', error);
      }
    },
    encryptPassword(publicKey, password) {
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      return encryptor.encrypt(password);
    },
    validateConfirmPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入新密码'));
      } else if (value !== this.passwordForm.newPassword) {
        callback(new Error('两次输入的新密码不一致'));
      } else {
        callback();
      }
    },
    validateField(field) {
      this.$refs.passwordForm.validateField(field);
    },
    validateNoWhitespace(rule, value, callback) {
      if (/\s/.test(value)) {
        callback(new Error('密码中不允许包含空白字符'));
      } else {
        callback();
      }
    }
  }
};
</script>
